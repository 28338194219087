.header h1{
    font-size: 30px;
    font-weight: bold;
}
.pagination{
    margin-top: 20px;
}

.select-content {
    background-color: #ffffff;
}
.select-item:hover,
.select-item:focus {
    background-color: #f3f4f6; /* Light gray on hover/focus */
}
.page-selector {
    background-color: #ffffff;
}

