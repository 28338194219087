/* Gym Cards Layout */
.gym-cards-container {
  display: flex;
  justify-content: flex-start; /* Aligns items to the start with less space between them */
  flex-wrap: wrap;
  gap: 75px; /* Adds uniform gap between cards */
  margin-top: 20px;
  margin-left: 30px; /* Remove the extra margin on the left */
}

  
  .gym-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 25%;
    margin-bottom: 20px;
    padding: 20px;
    text-align: center;
  }
  
  .gym-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .gym-info {
    margin-top: 10px;
  }
  
  /* Flexbox for the gym name and delete icon */
  .gym-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height:20px;
  }
  
  .gym-info p {
    height: 15px;
  }
  
  .gym-actions {
    margin-top: 10px;
  }
  
  .gym-actions .view-btn, .edit-btn {
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    margin-right: 10px;
    width: 45%;
    height: 35px;
  }

  .gym-actions .subsc-btn {
    background-color: #0f69df;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    margin-right: 10px;
    width: 100%;
    height: 35px;
  }
  
  .gym-actions .delete-icon {
    color: #dc3545;
    cursor: pointer;
  }
  
  .gym-actions .view-btn:hover, .edit-btn:hover, .delete-icon:hover {
    opacity: 0.8;
  }
  
/* Make the delete icon smaller and aligned properly */
.delete-icon {
    font-size: 1.2em;
    margin-left: 10px;
    cursor: pointer;
    color: #dc3545; /* Set the color to #dc3545 */
  }
  
  .delete-icon:hover {
    opacity: 0.8; /* Optional: Add hover effect to the icon */
  }
  