.gym-info {
  text-align: center;
  margin-top: 30px;
}

.icon-arrow-left {
  border: 2px solid #ccc; /* Border color */
  border-radius: 50%; /* Makes the border round */
  padding: 8px; /* Space inside the border */
  font-size: 16px; /* Icon size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, border-color 0.3s; /* Smooth hover effect */
}

.icon-arrow-left:hover {
  background-color: #f0f0f0; /* Light background on hover */
  border-color: #999; /* Change border color on hover */
}

.gym-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.gym-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-left: 2%;
}

.card {
  background-color: #d64545;
  color: white;
  width: 200px;
  padding: 20px;
  margin: 10px;
  text-align: center;
  border-radius: 8px;
}

.card h3 {
  font-size: 36px;
  margin: 0;
}

.card p {
  margin-top: 10px;
  font-size: 16px;
}
