/* Sidebar styling */
.sidebar {
    width: 14%;
    height: 100%;
    background-color: white;
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: width 0.3s ease;
}

.sidebar.collapsed {
    width: 10px;
}

.logo {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.logo img {
    width: 100%;
    height: auto;
}

nav ul {
    list-style-type: none;
    padding: 0;
}

nav ul li {
    margin: 15px 0;
}

nav ul li a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-left: 20px;
}

nav ul li a .menu-item-icon {
    margin-right: 10px;
}

.sidebar.collapsed .menu-item-icon {
    margin-right: 0;
    justify-content: center;
}

nav ul li a:hover {
    color: #007bff;
}

/* Settings and Log out positioning */
.sidebar-bottom {
    margin-top: auto;
    margin-bottom: 10px;
}

.nav-bottom-links {
    list-style: none;
    padding: 0;
}

.nav-bottom-links li {
    margin: 15px 0;
}

.nav-bottom-links a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.nav-bottom-links a .menu-item-icon {
    margin-right: 10px;
}

.sidebar.collapsed .nav-bottom-links a .menu-item-icon {
    margin-right: 0;
    justify-content: center;
}

.nav-bottom-links a:hover {
    color: #007bff;
}

/* Top icon */
.top-icon {
    display: flex;
    margin-bottom: 20px;
}

.menu-icon {
    font-size: 24px;
    cursor: pointer;
    color: #333;
}

/* Adjustments for collapsed sidebar */
.sidebar.collapsed .logo {
    display: none;
}

.sidebar.collapsed nav ul li a {
    justify-content: center;
}

.sidebar.collapsed nav ul li a .menu-item-icon {
    margin-right: 0;
}

.background{
    background-color: #f8f9fa;
    border-radius: 10px;
}

.sidebar.collapsed .background{
    background-color: white;
    border-radius: 10px;
    margin-left: -10px;
}

.sidebar.collapsed.nav .ul .li .a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-left: 0px;
}

  
  /* Main content styling */
  .main-content {
    margin-left: 14%; /* Push the content to the right to make space for the sidebar */
    padding: 40px;
    height: 100%; /* Full height for scrolling if necessary */
    overflow-y: auto; /* Allow scrolling if the content exceeds the viewport */
    background-color: #f8f9fa;
  }

  .main-content-collapsed {
    margin-left: 50px; /* Adjusted margin when the sidebar is collapsed */
}
  
  h1 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  p {
    height: 10px;
  }
  
  .header {
    display: flex;
    justify-content: space-between; /* This will align search bar to the left and other items to the right */
    align-items: center;
    margin-bottom: 20px;
    margin-top: -20px;

  }
  
  .search-bar {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 25px;
    width: 450px;
    outline: none;
    margin-left : 30px;
  }
  
  .header-right {
    display: flex;
    align-items: center;
    gap: 20px; /* Space between button, bell, and profile */
  }
  
  .add-gym-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .add-gym-button:hover {
    background-color: #d32f2f;
  }
  
  .icon-bell {
    font-size: 20px;
    cursor: pointer;
  }
  
  .profile-picture {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
  }

  
  .welcome-image {
    max-width: 10%;
    height: auto;
  }
  
  /* User Info Card */
  .user-info-card {
    background-color: #fff;
    margin-left: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 250px;
  }
  
  
  .profile-card-image {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin: 5px 0;
  }
  
  .role {
    font-size: 14px;
    font-weight: bold;
    margin: 5px 0;
  }
  /* Top icon above the logo */
.top-icon {
    display: flex;
    margin-bottom: 20px;
  }
  
  .menu-icon {
    font-size: 24px;
    cursor: pointer;
    color: #333;
  }
